import { graphql } from 'gatsby'
import React from 'react'
import { PostList } from '../../components/Blog/PostList'
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from '../../components/helpers'
import { PageContainer } from '../../components/layout/TextContainer'
import { Seo } from '../../components/utils'

export default function Blog({ data }) {
  const { blogSite } = data
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : []

  return (
    <div>
      <Seo
        title={blogSite.title}
        titleTemplate={' '}
        description={blogSite.description}
        keywords={blogSite.keywords}
      />
      <PageContainer
        heading={blogSite.title || 'ghostbuilder Blog'}
        subheading={blogSite.description}
      >
        {postNodes && <PostList posts={postNodes} />}
      </PageContainer>
    </div>
  )
}

export const blogQuery = graphql`
  query BlogPageQuery {
    blogSite: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          pixelTag
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`
