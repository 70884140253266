import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { isMobileDevice } from '../helpers'
import { Typography } from '../utils'
import { Link } from 'gatsby'

export const ImageCard = ({ children, as, href, ...rest }) => {
  if (as === 'a' && href) {
    return (
      <ImageContainerLink {...rest} to={href}>
        {children}
      </ImageContainerLink>
    )
  } else {
    return <ImageContainer {...rest}>{children}</ImageContainer>
  }
}

export const ImageCardImage = ({ src, alt }) => (
  <ImageBox>
    <Image src={src} loading="lazy" alt={alt} />
  </ImageBox>
)
export const ImageCardContent = ({ children }) => (
  <ImageContent>{children}</ImageContent>
)
export const ImageCardHeading = ({ children }) => (
  <ImageHeading variant="h3">{children}</ImageHeading>
)

export const ImageCardTime = ({ datetime }) => (
  <ImageTime datetime={datetime}>{datetime}</ImageTime>
)

export const ImageCardBody = ({ children, as = 'p' }) => (
  <ImageBody variant="p" as={as}>
    {children}
  </ImageBody>
)

const ImageContainerObject = {
  padding: '1rem',
  backgroundColor: 'white',
  color: ({ theme }) => theme.primary,
  borderRadius: '1rem',
  width: '100%',
  minHeight: '12rem',
  textDecoration: 'none',
  display: 'grid',
  gap: '1rem',
  gridTemplateRows: 'auto 1fr',
  '@media (min-width: 768px)': {
    minHeight: 'unset',
    gridTemplateRows: 'auto',
    gridTemplateColumns: '2fr 4fr',
  },
}
const ImageContainer = styled(motion.div)({
  ...ImageContainerObject,
})
const ImageContainerLink = styled(motion(Link))({
  ...ImageContainerObject,
})

const ImageContent = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-rows: auto auto 1fr;
  }
`

const ImageHeading = styled(Typography)`
  line-height: 150%;
  margin-bottom: ${isMobileDevice ? '0px' : '0.5rem'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
  @media (min-width: 768px) {
    font-size: 1.5rem;
    margin-right: 2rem;
  }
`
const ImageTime = styled.time`
  color: ${({ theme }) => theme.color.purple300};
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`
const ImageBox = styled.div`
  border-radius: 12px;
  height: 0;
  overflow: hidden;
  padding-top: 52.5%;
  position: relative;
  align-self: center;
`
const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const ImageBody = styled(Typography)`
  line-height: 150%;
  margin-right: 2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.color.purple400};
  align-self: flex-start;
`
