import React from 'react'
import styled from 'styled-components'
import useMedia from 'use-media'
import {
  ImageCard,
  ImageCardBody,
  ImageCardContent,
  ImageCardHeading,
  ImageCardImage,
  ImageCardTime,
} from '../Cards/ImageCard'
import { buildImageObj, getBlogUrl, imageUrlFor, toBlogDate } from '../helpers'
import { PortableText, Typography } from '../utils'

export function PostList({ posts }) {
  const isLargeScreen = useMedia({ minWidth: 768 })

  return (
    <Container>
      <Typography variant="h2" style={{ padding: '0 0 16px 16px' }}>
        Letzten Blog Beiträge
      </Typography>
      <BlogGrid key="BlogGrid">
        {posts &&
          posts.map((post) => (
            <ImageCard
              key={post.id}
              as="a"
              href={`${getBlogUrl(post.publishedAt, post.slug.current)}`}
              whileTap={{ scale: 0.98 }}
            >
              {post.mainImage && post.mainImage.asset && (
                <ImageCardImage
                  src={imageUrlFor(buildImageObj(post.mainImage))
                    .width(512)
                    .height(Math.floor((9 / 17.14) * 512))
                    .auto('format')
                    .url()}
                  alt={post.mainImage.alt}
                />
              )}
              <ImageCardContent>
                <ImageCardHeading>{post.title}</ImageCardHeading>
                <ImageCardTime datetime={toBlogDate(post.publishedAt)} />
                {isLargeScreen && post._rawExcerpt && (
                  <ImageCardBody as="div">
                    <PortableText blocks={post._rawExcerpt} />
                  </ImageCardBody>
                )}
              </ImageCardContent>
            </ImageCard>
          ))}
      </BlogGrid>
    </Container>
  )
}

const Container = styled.div`
  max-width: 1056px;
  margin: 0 auto;
`
const Grid = styled.div`
  margin: 1rem 1rem 0;
  display: grid;
  justify-content: center;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`

const BlogGrid = styled(Grid)`
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  @media (min-width: 768px) {
    grid-template-columns: 1fr;
  }
`
